@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

#root,
.App {
  height: 100vh;
  background-color: #f7f7fa;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f1e2bf;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e6baba;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

}
@layer base {
  @font-face {
      font-family: SF Pro;
      src: url('./fonts/SFPro.otf');
    }
   @font-face {
      font-family: SF Pro bold;
      src: url('./fonts/SFProBold.otf');
    }
  @font-face {
      font-family: circe;
      src: url('https: //fonts.cdnfonts.com/css/circe');
    }
}

@layer base {
  img {
    @apply inline-block;
  }
}